import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import React, { useEffect, useState } from 'react';
import { Container, Image, Card, Button, Row, Col } from 'react-bootstrap';
import { scroller } from 'react-scroll'

function App() {
    const scrollTo = (className) => {
        scroller.scrollTo(className, {
            duration: 800,
            delay: 0,
            smooth: "easeInOutQuart",
        });
    };

    const companies = ["self", "factset", "oracle", "zone", "portfolio"];

    const [companyIndex, setCompanyIndex] = useState(0);
    const scrollHandler = (event) => {
        if (event.deltaY > 0 || event.keyCode === 40 || event.type === "click") {
            if (companyIndex < companies.length-1) {
                const newIndex = parseInt(companyIndex) + 1;
                setCompanyIndex(newIndex);
                scrollTo(companies[newIndex]);
            }
        } else if (event.deltaY < 0 || event.keyCode === 38) {
            if (companyIndex > 0) {
                const newIndex = parseInt(companyIndex) - 1;
                setCompanyIndex(newIndex);
                scrollTo(companies[newIndex]);
            } else if (companyIndex === companies.length) {

            }
        }
    }

    const scrollToTop = (event) => {
        setCompanyIndex(0);
        scrollTo(companies[0]);
    }

    useEffect(() => {
        window.addEventListener('wheel', scrollHandler);
        window.addEventListener('keyup', scrollHandler);
        return () => {
            window.removeEventListener('wheel', scrollHandler);
            window.removeEventListener('keyup', scrollHandler);
        }
    }, [companyIndex]);

    const path = process.env.PUBLIC_URL;

    return (
        <Container className="main">
            <Container className="self" >
                <Container className="workHistory">
                    <Container>
                        Lyle Stephen Prospero
                    </Container>
                    <Image className="downButton" src={`${path}/down_button.png`} onClick={scrollHandler} />
                </Container>
            </Container>

            <Container className="factset" >
                <Container fluid className="bgImage" />
                <Container className="workHistory">
                    <Container>
                        <span className="companyName">FactSet</span>
                        <br />
                        FactSet provides financial data to help professionals carry out investment decisions.
                        On top of this, FactSet's proprietary software offers analytics tools, portfolio management solutions, and visualization and reporting features.
                        <br /><br />
                        As part of the <i>Content Integration Engineering</i> group, it is our responsibility to maintain the update systems
                        that integrate data from 3rd party providers with the <i>FactSet Database</i> (FDB).
                        Using MS SQL, we augment the already specialized features of the FDB to make fetches event faster and more efficient..
                        <br /><br />
                        I managed the <i>Fixed Income Benchmarks</i> and <i>Estimates and Fundamentals</i> team.
                        <br /><br />
                    </Container>
                    <Container className="tech">
                        Perl &#x2022; BASH &#x2022; Perforce &#x2022; ETL &#x2022; MS SQL
                    </Container>
                    <Image className="downButton" src={`${path}/down_button.png`} onClick={scrollHandler} />
                </Container>
            </Container>

            <Container className="oracle" >
                <Container fluid className="bgImage" />
                <Container className="workHistory">
                    <Container>
                        <span className="companyName">Oracle NetSuite</span>
                        <br />
                        NetSuite is one of the pioneers when it comes to the cloud-based <i>Enterprise Resource Planning</i> (ERP) scene. It provides financial management features to streamline <i>Order-to-cash</i> and <i>Procure-to-pay</i> workflows of their clients,
                        support for <i>Customer Relationship Management</i> (CRM), and various workflows to manage employees such as for payroll and attendance.
                        <br /><br />
                        In the <i>Localization</i> team, we implemented features specific to the JAPAC and ANZ regions, including government-compliant and translatable forms and reports.
                        We pioneered the integration of Australia's <i>SISS Data Services</i> with NetSuite to ensure a secure method of accessing and storing bank-approved data.
                        <br /><br />
                        I was a <i>Principal Platform Software Engineer</i> and a <i>Tech Developer Lead</i>.
                        <br /><br />
                    </Container>
                    <Container className="tech">
                        SuiteScript &#x2022; SuiteFlow &#x2022; SuiteTalk &#x2022; SuiteAnalytics &#x2022; TeamCity
                    </Container>
                    <Image className="downButton" src={`${path}/down_button.png`} onClick={scrollHandler} />
                </Container>
            </Container>

            <Container className="zone" >
                <Container fluid className="bgImage" />
                <Container className="workHistory">
                    <Container>
                        <span className="companyName">Zone & Company</span>
                        <br />
                        As a NetSuite SDN partner, Zone offers advance approval routing and workflows, OCR-based data capture and mapping, and advance billing solutions on top of the native NetSuite billing feature.
                        <br /><br />
                        Continuing my NetSuite career under the <i>ZoneCapture</i> team, we leveraged the potential of web-based applications, through ReactJs and Material UI, to improve the experience of users while working with NetSuite.
                        The team implemented a 3-way matching process, which will allow users to map vendor bill lines, purchase orders, and item-level data, streamlined into a single-page application.
                        <br /><br />
                        I attained a <i>NetSuite SuiteFoundation Certification</i> (24655) through the sponsorhip of the company.
                    </Container>
                    <Container className="tech">
                        OCR &#x2022; ReactJS &#x2022; Jest &#x2022; Git &#x2022; Node.js &#x2022; Attlasian
                    </Container>
                    <Image className="downButton" src={`${path}/down_button.png`} onClick={scrollHandler} />
                </Container>
            </Container>

            <Container className="portfolio" >
                <Container fluid className="bgImage" />
                <Container className="inner" >
                    <Container>
                        <span className="companyName">Portfolio</span>
                        <Container>
                            <Row className="project">
                                <Col className="description-left">
                                    <span className="title">Brofactor</span>
                                    <br /><br />
                                    Designed to promote the culture of appreciation within the team,
                                    this can help scrum masters and managers facilitate sprint retro meetings
                                    and gauge team dynamics and engagement.
                                    <br /><br />
                                    <Button href="http://brofactor.vercel.app" target="_blank" variant="secondary">Go to website</Button>
                                </Col>
                                <Col>
                                    <Image src={`${path}/assets/brofactor.png`} />
                                </Col>
                            </Row>
                            <Row className="project">
                                <Col>
                                    <Image src={`${path}/assets/macropoint.png`} />
                                </Col>
                                <Col className="description-right">
                                    <span className="title">Macropoint</span>
                                    <br /><br />
                                    Inspired by NetSuite, this project is a prototype of an ERP application written in React.
                                    <br /><br />
                                    <Button href="http://macropoint.vercel.app" target="_blank" variant="secondary">Go to website</Button>
                                </Col>
                            </Row>
                        </Container>
                    </Container>
                    <Container className="tech">
                        <span onClick={scrollToTop} >Back to Top</span>
                    </Container>
                </Container>
            </Container>
        </Container>
  );
}

export default App;
